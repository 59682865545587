export default [
    {
      _name: 'CSidebarNav',
      _children: [
        {
            _name: 'CSidebarNavItem',
            name: 'User list',
            to: '/manager/userlist',
            icon: 'cil-user'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Plan Allocator',
          to: '/manager/planallocate',
          icon: 'cil-list'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Plan Creator',
          to: '/manager/createplan',
          icon: 'cil-list'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Roles Info',
          to: '/manager/roles',
          icon: 'cil-info',
          // badge: {
          //   color: 'light',
          //   text: 'NEW'
          // }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Report',
          to: '/manager/dash',
          icon: 'cil-speedometer',
          // badge: {
          //   color: 'primary',
          //   text: 'NEW'
          // }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Checks Report',
          to: '/manager/checksreprot',
          icon: 'cil-speedometer',
          badge: {
            color: 'primary',
            text: 'NEW'
          }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Error Logs',
          to: '/manager/errorlog',
          icon: 'cil-file'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Offer list',
          to: '/manager/offerlist',
          icon: 'cil-circle'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Traffic Source list',
          to: '/manager/tslist',
          icon: 'cil-text'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Campaign list',
          to: '/manager/campaignlist',
          icon: 'cil-cc'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Wnp Search Payload',
          to: '/manager/wnpsearchpayload',
          icon: 'cil-search'
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Logout',
            to: '/pages/logout',
            icon: { name: 'cil-power-standby', class: 'text-white' },
            _class: 'bg-danger text-white',
        },
      ]
    }
  ]